@import "../../util/variables";
@import "~@blueprintjs/core/lib/scss/variables";

.recipe-list-item {
  display: flex;
  margin: 0 15px 20px 0;
  padding: 20px;
  height: 150px;

  // background-color: $white;
  // border-radius: $pt-border-radius;
  // box-shadow: $pt-elevation-shadow-0;
  // &:hover {
  //   box-shadow: $pt-elevation-shadow-2;
  // }

  .thumbnail {
    width: 110px;
    height: 110px;
    min-width: 110px;
    min-height: 110px;
    border-radius: 5px;
    .recipe-image {
      width: 110px;
      height: 110px;
      border-radius: 5px;
      object-fit: cover;
    }
  }
  .recipe-description {
    max-width: 80%;
    white-space: pre-wrap;
    max-height: 3.6em;
    line-height: 1.2em;
  }

  .recipe-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
    width: calc(100% - 130px);
    padding-left: 20px;

    .title-category-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-family: "Montserrat Alternates", sans-serif;
      max-height: 50px;
      text-overflow: ellipsis;
      overflow: hidden;
      .recipe-category {
        flex-shrink: 0;
        font-style: italic;
        margin-left: 20px;
      }
    }

    .info-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .recipe-date {
        align-self: flex-end;
      }
    }
  }

  .recipe-title.#{$bp-ns}-skeleton {
    height: 25px;
    width: 300px;
  }
  .recipe-category.#{$bp-ns}-skeleton {
    height: 20px;
    width: 250px;
  }
  .recipe-description.#{$bp-ns}-skeleton {
    height: 3.6em;
    width: 450px;
  }
}

.ellipsis {
  overflow: hidden;
  position: relative;
}

.ellipsis::after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.2em;
  background: linear-gradient(to right, rgba($light-gray5, 0), rgba($light-gray5, 0.7) 50%);
}
.#{$bp-ns}-dark .ellipsis::after {
  background: linear-gradient(
    to right,
    rgba($dark-content-background-color, 0),
    rgba($dark-content-background-color, 0.7) 50%
  );
}

.mobile {
  .recipe-list-item {
    max-width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    padding: unset;
    .recipe-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // text-align: center;
      margin-bottom: 10px;
    }
    .recipe-content-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .recipe-description {
        max-width: unset;
        max-height: calc(5 * 1.2em);
        line-height: 1.2em;
      }
      .recipe-category {
        font-family: "Montserrat Alternates", sans-serif;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-style: italic;
        text-align: end;
        margin: unset;
        color: $pt-link-color;
        .#{$bp-ns}-dark & {
          color: $pt-dark-link-color;
        }
      }
    }
  }
  .recipe-list-divider {
    max-width: calc(100% - 50px); // 2 * 20px padding + 2 * 5px margin on divider
  }
}
