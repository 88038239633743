@import "../util/variables.scss";
@import "~@blueprintjs/core/lib/scss/variables";

.page {
  // padding-top: 85px;
  @media only screen and (max-width: $mobile-width), (max-height: $mobile-width) {
    padding-top: $pt-navbar-height;
  }
  position: relative;
}

.mobile-header-wrapper {
  .mobile-header-content {
    height: $pt-navbar-height;
    padding: 0 15px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $white;
    .#{$bp-ns}-dark & {
      background-color: $dark-gray5;
    }

    .left-align {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .right-align {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .logo {
      max-height: 50px;
      margin-left: 10px;
    }

    .recipe-search {
      margin-left: 5px;

      .#{$bp-ns}-input {
        transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        width: 40vw;
      }
      .#{$bp-ns}-input:focus {
        width: 45vw;
      }
    }
  }

  .mobile-header-menu {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: auto;
    padding: unset;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 10;
    .#{$bp-ns}-dark & {
      background-color: $dark-gray5;
      box-shadow: 0 0 0 1px rgba(17,20,24,.2),0 0 0 rgba(17,20,24,0),0 1px 1px rgba(17,20,24,.4);
    }
    .settings {
      margin: 0 20px;
      padding: 10px 0;
      display: flex;
      align-items: center;

      .#{$bp-ns}-switch {
        margin-bottom: 0;
      }

      .spacer {
        flex-grow: 1;
      }
    }
    .navigation {
      padding-bottom: 10px;
      margin: 0 20px;
    }
  }
}

.mobile-header-menu-clicker {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
}
