@import "~@blueprintjs/core/lib/scss/variables";
@import "util/variables";
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,500;0,600;1,500&family=Ubuntu:wght@400;700&display=swap");

body,
.#{$bp-ns}-button,
.#{$bp-ns}-input,
.#{$bp-ns}-input-ghost {
  font-family: "Ubuntu", sans-serif;
}
body {
  width: 100%;
  .page {
    min-height: 100vh;
    background-color: $content-background-color;
    .body {
      display: flex;
      max-width: 1280px;
      margin: 0 auto;
      // padding-top: 10px;
      height: 100%;
      .main-content.unique {
        max-width: 100%;
      }
      .main-content {
        min-height: 50vh; // fix for weird behaviour in the top margin of the left menu
        flex: 0 1 75%;
        max-width: 75%;
        padding: 0 20px;
      }
    }
  }
}

.#{$ns}-dark {
  .page {
    background-color: $dark-content-background-color;
  }
}

// toast container was position: absolute and scrolled with the page
.#{$ns}-toast-container.#{$ns}-toast-container-inline {
  position: fixed;
}

.#{$bp-ns}-dialog {
  margin: 30px 20px;
}

:focus {
  outline: none !important;
}
button::-moz-focus-inner {
  border: 0;
}

.mobile-menu-item {
  font-size: larger;
  line-height: 1.5em;
}
