@import "~@blueprintjs/core/lib/scss/variables";

.main-content {
  margin-top: 40px;
  .recipe-list-action {
    // position: sticky;
    // top: 105px;
    padding: 20px 15px;
    margin: 35px 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
  }
  .notification-asker {
    margin: 15px;
    width: auto;
    .title-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  a {
    text-decoration: none;
  }

  .error {
    margin: 20px auto;
    text-align: center;
    font-family: "Montserrat Alternates", sans-serif;
    padding: 0 25px;
  }
}
.side-menu .sort-recipes .#{$bp-ns}-popover-wrapper .#{$bp-ns}-popover-target {
  display: unset;
}

.fab-add {
  position: fixed;
  top: calc(100vh - 100px);
  // bottom: 30px;
  right: 30px;
  font-size: 40px !important;
  // background-color: $green2;
  // color: #f5f8fa;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 4px 8px rgba(16, 22, 26, 0.4), 0 9px 46px 6px rgba(16, 22, 26, 0.4) !important;
  border-radius: 100%;
  border: none;
  outline: none;
}

.mobile {
  .page .body .main-content {
    margin-top: 10px;
    flex: unset;
    max-width: unset;
    width: 100%;
    padding-top: unset;
  }
  .sort-filter {
    .menu-item {
      margin: 20px auto;
      max-width: 80%;
    }
  }
}

.recipe-search {
  margin-right: 15px;
}

.nav-icon2-wrapper {
  padding: 0 10px;
}

#nav-icon2 {
  width: 24px;
  height: 18px;
  position: relative;
  margin: 25px auto;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 2.4px;
  width: 50%;
  opacity: 1;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  background: $dark-gray1;
}

.#{$bp-ns}-dark {
  #nav-icon2 span {
    background: $light-gray5;
  }
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 2.4px 2.4px 0;
}

#nav-icon2 span:nth-child(odd) {
  left: 0px;
  border-radius: 2.4px 0 0 2.4px;
}

#nav-icon2 span:nth-child(1),
#nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3),
#nav-icon2 span:nth-child(4) {
  top: 7.2px;
}

#nav-icon2 span:nth-child(5),
#nav-icon2 span:nth-child(6) {
  top: 14.4px;
}

#nav-icon2.open span:nth-child(1),
#nav-icon2.open span:nth-child(6) {
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),
#nav-icon2.open span:nth-child(5) {
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 2px;
  top: 2.8px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 2px);
  top: 2.8px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 2px;
  top: 11.6px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 2px);
  top: 11.6px;
}
